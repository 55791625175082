<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <tabsComp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-35px !important;`">
      <v-row style="margin-top:45px;">

        <v-col cols="12" md="12">
          <form autocomplete="off">
            <div class="searchTop" style="margin-inline-end:-20px !important;">
              <div class="searchBar">
                <div>
                  <div>{{lang.chose_from_list}} {{lang.start_date}}</div>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                  </b-input-group>
                </div>

                <div>
                  <div>{{lang.chose_from_list}} {{lang.end_date}}</div>
                  <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                  </b-input-group>
                </div>

                <div>
                  <div>{{lang.mobile}}</div>
                  <b-form-input class="inborder" v-model="mobile" style="background:#efefef;" />
                </div>

                <div>
                  <div>{{lang.checkup_number}}</div>
                  <b-form-input class="inborder" v-model="checkup_number" style="background:#efefef;" />
                </div>

                <div v-if="$store.state.licenseType.cars">
                  <div>{{lang.plate_number}}</div>
                  <div class="plateNumber">
                    <b-form-input class="inborder" v-model="pl.c1" style="background:#fff700 !important;width:30px;" />
                    <b-form-input class="inborder" v-model="pl.c2" style="border-inline:none !important;background:#fff700 !important;width:30px;" />
                    <b-form-input class="inborder" v-model="pl.c3" style="border-inline-end:none !important;background:#fff700 !important;width:30px;" />
                    <b-form-input class="inborder" v-model="pl.text" style="background:#fff700 !important;width:60px;" />
                  </div>
                </div>
                <div>
                  <div>&nbsp;</div>
                  <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getCheckup()">{{lang.search}}</b-button>
                </div>
              </div>
              <div style="margin-inline-end:17px;">
                <b-button
                  @click="addCheck()"
                  v-b-toggle.add_check
                  class="btn-sm btn"
                  style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
                  id="multiply-button" 
                >
                <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_check}}</div>
                
                </b-button>
              </div>
            </div>
          </form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+`;`">
            <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              width="100%"
              id="MainTable"
              hide-default-footer
              fixed-header
            >
                <template v-slot:item="row">
                    <tr>
                        <td style="width:50px;text-align:center;">{{ row.item.id }}</td>
                        <td class="text-center" nowrap style="text-align:center;">{{ row.item.branch }}</td>
                        <td class="text-center" nowrap style="text-align:center;">{{ row.item.customer_name }}</td>
                        <td class="text-center" nowrap style="text-align:center;">{{ row.item.owner_name }}</td>
                        <td class="text-center" nowrap style="text-align:center;">{{ row.item.owner_mobile_number }}</td>
                        <td style="width:100px;text-align:center;">{{ row.item.plate_number }}</td>
                        <td style="width:100px;text-align:center;">{{ row.item.model }}</td>
                        <td :style="`width:80px;text-align:center;`">{{ row.item.status }}</td>
                        <td
                            style="
                            cursor: pointer;
                            width: 85px;
                            border-top: 1px solid #bbb !important;border-bottom: 1px solid #bbb !important;"
                            class="backBlue text-center"
                            v-b-toggle.add_check
                        >
                            <div style="color:#fff !important;" @click="delInvo(row.item)">
                            {{lang.update}}</div>
                        </td>
                        <td
                            style="
                            cursor: pointer;
                            width: 85px;
                            border-top: 1px solid #bbb !important;border-bottom: 1px solid #bbb !important;
                            "
                            class="backGreen text-center"
                        >
                            <div v-b-toggle.pview_invoice @click="printIt(row.item)">
                            {{lang.print}}</div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-col>
     </v-row>
      </v-row>
      <addCheckup ref="addCheckup" />
      
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import TabsComp from '@/components/tabsComp.vue'
import addCheckup from '@/components/addCheckup.vue'
import {SnotifyPosition} from 'vue-snotify';
import axios from 'axios'
export default {
    components: {
        HeaderPortrate,ExportsPrint,TabsComp,addCheckup
    },
    data() {
        return {
            search: '',
            mobile: '',
            checkup_number: '',
            tablerows: [],
            pl: {
                c1: '',
                c2: '',
                c3: '',
                text: ''
            },
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            let t = [
                { 
                    text: "#", align: 'center',
                    filterable: false, value: 'id',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.branch_name, align: 'center',
                    filterable: false, value: 'company_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.company_name, align: 'center',
                    filterable: false, value: 'company_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.owner_name, align: 'center',
                    filterable: false, value: 'owner_name',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.owner_mobile_number, align: 'center',
                    filterable: false, value: 'owner_mobile_number',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.car_model, align: 'center',
                    filterable: false, value: 'model',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.plate_number, align: 'center',
                    filterable: false, value: 'plate_number',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.status, align: 'center',
                    filterable: false, value: 'status',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.action, align: 'center',
                    filterable: false, value: '',
                    sortable: false,class: 'backBlack'
                },
                { 
                    text: this.lang.print, align: 'center',
                    filterable: false, value: '',
                    sortable: false,class: 'backBlack'
                },
            ]

            return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
    methods: {
      delInvo(item){
        this.$refs.addCheckup.reset();
        this.$refs.addCheckup.$refs.addButton.disabled = false;
        this.$refs.addCheckup.getCheckup(item.id)
      },
      printIt (item)
        {
            let src = '';
                src = '../api/print.php?checkId='+item.id;
            window.open(src,"_blank")
        },
      goDel(id){
        const post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
        post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("type","delCheckup");
        post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append('data[id]',id);
        axios.post(
            this.$store.state.SAMCOTEC.r_path, post
        ).then((response) => {
          const message = this.lang.deleted_successfuly
          this.$snotify.error(message, this.lang.alert, {
              timeout: 1000000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: SnotifyPosition.centerCenter,
              buttons: [
                  {
                      text: this.lang.close, 
                      action: (toast) => {
                          this.$snotify.remove(toast.id); 
                      } 
                  },
              ]
          });
          this.getCheckup();
        });
        
      },
      addCheck() {
        this.$refs.addCheckup.reset();
        this.$refs.addCheckup.$refs.addButton.disabled = false;
      },
      getCheckup() {
        const post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
        post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("type","getCurrentCheckup");
        post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append('data[id]',0);
        axios.post(
            this.$store.state.SAMCOTEC.r_path, post
        ).then((response) => {
            this.tablerows = response.data.results.data;
        });
        
      }
    },
    created() {
      this.getCheckup();
    }
}
</script>
